<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.orderNo"
              placeholder="订单编号"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 130px">
          <a-input
              class="input"
              v-model="queryParams.uid"
              placeholder="下单人ID"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 130px">
          <a-input
              class="input"
              v-model="queryParams.lawyerUid"
              placeholder="律师ID"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 130px">
          <a-select
              class="input"
              v-model="queryParams.status"
              placeholder="状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict['lawOrderStatus']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 130px">
          <a-select
              class="input"
              v-model="queryParams.payStatus"
              placeholder="支付状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict['lawOrderPayStatus']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.bizTitle"
              placeholder="业务类型"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
<!--        <a-button v-hasPermission="'qrcode:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">-->
<!--          新增-->
<!--        </a-button>-->
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="record.userInfo.avatar" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.userInfo.avatar || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.userInfo.avatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('lawOrderStatus', 'color')">{{
            record.status | dictName("lawOrderStatus")
          }}
        </a-tag>
      </template>
      <template slot="payStatus" slot-scope="text, record">
        <a-tag :color="record.payStatus | dictName('lawOrderPayStatus', 'color')">{{
            record.payStatus | dictName("lawOrderPayStatus")
          }}
        </a-tag>
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                style="color: red"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyOrderModal.vue";

export default {
  name: "LawOrderList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "law/order/listByAdmin",
      listMethod: "get",
    };
  },
  computed: {
    columns() {
      this.dataSource = this.dataSource.map((item) => {
        try {
          item.media = JSON.parse(item.media) || [];
        } catch (error) {
          item.media = [];
        }
        return item;
      });
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "订单编号",
          align: "center",
          width: 180,
          dataIndex: "orderNo",
        },
        {
          title: "下单人ID",
          align: "center",
          width: 100,
          dataIndex: "uid",
        },
        {
          title: "下单人名称",
          align: "center",
          width: 150,
          dataIndex: "userInfo.username",
        },
        {
          title: "订单业务名称",
          align: "center",
          width: 200,
          dataIndex: "bizTitle",
        },
        {
          title: "律师ID",
          align: "center",
          width: 100,
          dataIndex: "lawyerUid",
        },
        {
          title: "律师名称",
          align: "center",
          width: 150,
          dataIndex: "lawyerInfo.username",
        },
        {
          title: "状态",
          align: "center",
          width: 80,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "金额",
          align: "center",
          width: 80,
          dataIndex: "amount",
        },
        {
          title: "下单时间",
          align: "center",
          width: 150,
          dataIndex: "createTime",
        },
        {
          title: "支付状态",
          align: "center",
          width: 80,
          dataIndex: "payStatus",
          scopedSlots: {customRender: "payStatus"},
        },
        // {
        //   title: "完成时间",
        //   align: "center",
        //   width: 150,
        //   dataIndex: "finishTime",
        // },
        // {
        //   title: "背调报告结果",
        //   align: "center",
        //   width: 300,
        //   dataIndex: "reportUrl",
        //   scopedSlots: {customRender: "reportUrl"},
        // },
        {
          title: "订单说明",
          align: "center",
          width: 200,
          ellipsis: true,
          dataIndex: "orderDesc"
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },
    add() {
      this.$refs.modifyModal.showModal(null, 'new');
    },
    getDelete(e, record) {
      let that = this;
      that.$message.info("暂未实现");
      this.$confirm({
        title: "删除确认",
        content: "警告：刪除后将无法恢复！您确定要删除订单【" + record.orderNo + "】吗？！",
        centered: true,
        onOk() {
          that.$post("law/order/delete", {orderId:record.id}).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
